import { Modal } from "antd";
import { useFormik } from "formik";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { folderDeleteValidationScheme } from "../../schemas/formValidationScheme";
import { deleteDocument } from "../../redux/EngageTranslation/EngageTranslationAction";

type FileUploadModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
};

function ConfirmDeleteModal(props: FileUploadModalProps) {
  const selectedDocumentID = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.documentTabState.selectedDocumentID);
  const selectedID = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.documentTabState.selectedID);
  const selectedDocumentName = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.documentTabState.selectedDocumentName);

  const dispatch = useAppDispatch();
  const ConfirmDeleteModalContent = () => {
    type ObjectDeletionType = {
      confirmText: string;
    };
    async function onSubmit() {
      await dispatch(deleteDocument({ documentID: selectedDocumentID, ID: selectedID }));
      props.close();
    }

    const { values, touched, errors, handleChange, setFieldValue, handleSubmit, handleBlur, setFormikState, setErrors, isValid, setFieldError } = useFormik<ObjectDeletionType>({
      initialValues: {
        confirmText: "",
      },
      validationSchema: folderDeleteValidationScheme,
      onSubmit,
    });

    //This useEffect is used to validate the from when mounted, by set error to some other field that is not in the form. To make sure that the button to submit will disabled initially.
    useEffect(() => {
      setFieldError("initialError", "Please enter the correct text");
    }, []);
    // console.log(errors);

    return (
      <form
        className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation"
        onSubmit={handleSubmit}
        id="createNewFolderForm">
        <h4>Delete</h4>
        <div className="d-flex flex-column gap-1">
          <p>
            To confirm deletion of <b>{selectedDocumentName}</b> (ID: {selectedDocumentID} ({selectedID})), type <b>permanently delete</b> in the text input field.
          </p>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column">
              <input
                type="text"
                id="confirmText"
                placeholder="permanently delete"
                autoComplete="off"
                onChange={(e) => {
                  const value = e.target.value || "";
                  setFieldValue("confirmText", value.toLowerCase());
                }}
                maxLength={18}
                className={`form-control ${errors.confirmText ? "error" : ""} `}
                value={values.confirmText}
              />

              {errors.confirmText && <p className="error-msg">{errors.confirmText}</p>}
            </div>

            <div className="d-flex gap-4 justify-content-end mt-3">
              <button
                type="submit"
                form="createNewFolderForm"
                className="button"
                disabled={!isValid}>
                Delete
              </button>
              <button
                type="button"
                onClick={() => {
                  props.close();
                }}
                // form="createNewFolderForm"
                className="button warning">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };
  //   const FileUploadModalWidth = "30%";
  return (
    <Modal
      //   width={FileUploadModalWidth}
      centered
      zIndex={10000}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <ConfirmDeleteModalContent />
    </Modal>
  );
}
function arePropsEqual(oldProps: FileUploadModalProps, newProps: FileUploadModalProps) {
  return oldProps.openState === newProps.openState;
}

export default memo(ConfirmDeleteModal, arePropsEqual);
