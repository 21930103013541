import { Modal } from "antd";
import AuditModalContent from "./AuditModalContent";

const AuditLogModal = (props: any) => {
  return (
    <Modal
      centered
      width={700}
      zIndex={1500}
      footer={[]}
      onCancel={props.close}
      open={props.openState}
    >
      <AuditModalContent close={props.close} />
    </Modal>
  );
};

export default AuditLogModal;
