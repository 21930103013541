const region = "us-east-1";
const origin = window.location.origin;

const staging = {
  appSettingsData: {
    // generate a file format limit of video file
    federatedEngageAIProvider: "wl-stg-engage-translate-idp",
  },

  amplify_config: {
    Auth: {
      mandatorySignIn: true,
      region: region,
      userPoolId: "us-east-1_BN1GJcYWG",
      userPoolWebClientId: "7kq4maf3fp4aiert7b9lp6kul9",
    },
    oauth: {
      domain: "wl-stg-engage-translate.auth.us-east-1.amazoncognito.com",
      scope: [],
      redirectSignIn: `${origin}/view`,
      redirectSignOut: `${origin}/`,
      responseType: "code",
      clientId: "7kq4maf3fp4aiert7b9lp6kul9",
    },
    API: {
      endpoints: [
        {
          name: "engage-translate",
          // endpoint: "https://api.dev.wl-dms.com",
          endpoint: "https://api.stg.translate.wl-engage.com",
          region: region,
        },
      ],
    },
  },
};
// type StagingType = typeof staging

export type StagingType = typeof staging;
export default staging;
