import { Modal } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../../redux/Store";

type FileUploadModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
};

function TransferToQCStatusModal(props: FileUploadModalProps) {
  const transferState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.viewDocumentState);

  const TransferToQCStatusModalContent = () => {
    const navigate = useNavigate();

    return (
      <div
        className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation"
        id="createNewFolderForm">
        <h4>Transfer to QC</h4>
        <div className="d-flex flex-column gap-1">
          {transferState.qcError ? (
            <div className="d-flex flex-column gap-2">
              <div>
                <p>Transfer to QC unsuccessful</p>
                <p className="text-danger">Error: {transferState.qcError}</p>
              </div>

              <div className="d-flex gap-4 justify-content-center">
                <button
                  onClick={() => props.close()}
                  className="button">
                  Ok
                </button>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column gap-2">
              <p>Transferred to QC successfully. Click Ok to return</p>
              <div className="d-flex gap-4 justify-content-center">
                <button
                  onClick={() => navigate(-1)}
                  className="button">
                  Ok
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      centered
      zIndex={10000}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <TransferToQCStatusModalContent />
    </Modal>
  );
}

export default TransferToQCStatusModal;
