import { useEffect, useState } from "react";
import TranslateTitleImg from "../../assets/img/wl-engage-translate-splashpg.png";
import TranscriptTitleImg from "../../assets/img/wl-engage-transcribe-splashpg.png";
import TranslateTitleLogo from "../../assets/img/engage translate_logo_slate.png";
import TranscriptTitleLogo from "../../assets/img/engage transcribe_logo_slate.png";
import SynthesisLogo from "../../assets/img/engage_synthesis_logo_slate.png";
import SynthesisImg from "../../assets/img/wl-engage-synthesize.png";
import { useNavigate } from "react-router";
import { Auth, Hub } from "aws-amplify";
import { FederatedEngageAIProvider } from "../../constant/constant";
import { Spin } from "antd";
import "./LoginPage.css";

interface LoginPageProps {}

function LoginPage(props: LoginPageProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    Hub.listen("auth", (data: any) => {
      switch (data.payload.event) {
        case "signIn":
          localStorage.setItem("cognitoUser", "true");
          localStorage.setItem("isAuthenticated", "true");
          break;
        case "signIn_failure":
          setLoading(false);
          break;
        default:
          setLoading(false);
          break;
      }
    });
  });

  const federatedLogin = async () => {
    setLoading(true);
    await Auth.federatedSignIn({ provider: FederatedEngageAIProvider });
    // const options = {
    //   pathname: "/view",
    //   search: `=text-translate`,
    // };
    // navigate(options);
  };

  return (
    <Spin spinning={loading}>
      <div className="container">
        <div
          className="img-box"
          onClick={() => {
            federatedLogin();
          }}>
          <img
            className="img-box-img"
            src={TranslateTitleImg}
            alt="translate"
          />
          <img
            className="transcribe-logo"
            src={TranslateTitleLogo}
            alt="translate"
          />
        </div>
        <div className="img-box">
          <img
            className="img-box-img"
            src={TranscriptTitleImg}
            alt="translate"
          />
          <img
            className="img-box-logo"
            src={TranscriptTitleLogo}
            alt="translate"
          />
        </div>
        <div className="img-box">
          <img
            className="img-box-img"
            src={SynthesisImg}
            alt="translate"
          />
          <img
            className="gpt"
            src={SynthesisLogo}
            alt="gpt"
          />
        </div>
      </div>
      //{" "}
    </Spin>
  );
}

export default LoginPage;
