import { FontIcon, Icon } from "@fluentui/react";
import { ColumnsType } from "antd/es/table";
import IsDataNull from "../Template/IsDataNull";
import { Tooltip } from "antd";
import { useAppDispatch } from "../../redux/useAppDispatch";
import ProjectsTabAction from "../Template/ProjectsTabAction";
import CustomColumn from "../Template/CustomColumn";
import ShortenedName from "../Template/ShortenedName";

interface EngageTranslationTableColumnProperties {
  id: number;
  fileName: string;
  engageID: string;
  format: string;
  pages: number;
  client: string;
  sourceLanguage: string;
  outputLanguage: string;
  quickUpload?: string;
  status: "Processing" | "Failed" | "Mixed" | "QC Ready" | "In Queue" | "Completed" | "QC In Progress";
}

const EngageTranslationTableColumns: ColumnsType<EngageTranslationTableColumnProperties> = [
  {
    title: "ID",
    dataIndex: "id",
    sorter: true,
    width: 70,
    defaultSortOrder: "descend",
    render: (data: number, record: any) => {
      return (
        <div>
          <b>{+record.documentId}</b> ({+data})
        </div>
      );
    },
  },
  {
    title: "Document",
    dataIndex: "name",
    sorter: true,
    width: "20%",
    render: (data) => {
      // const maxLength = 30;

      // if (data.length > maxLength) {
      //   return (
      //     <ShortenedName
      //       data={data}
      //       maxLength={maxLength}
      //       toolTipText={data}
      //     />
      //   );
      // } else {
      //   return <IsDataNull data={data} />;
      // }

      return <IsDataNull data={data} />;
    },
  },
  {
    title: "Date & Timestamp",
    dataIndex: "createdAt",
    sorter: true,
    width: 160,
    render: (data) => {
      const dataSplit = data.split("T");
      const date = dataSplit[0];
      const time = dataSplit[1].split(".")[0];
      const dateAndTime = date + " at " + time;
      return dateAndTime;
    },
  },
  {
    title: "Format",
    dataIndex: "format",
    sorter: true,
    width: 60,
    render: (data) => {
      if (data === "pdf") {
        return <FontIcon iconName="PDF" />;
      } else if (data === "docx" || "doc") {
        return <FontIcon iconName="WordDocument" />;
      } else {
        <IsDataNull data={data} />;
      }
    },
  },
  {
    title: "Source",
    dataIndex: "sourceLanguage",
    sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data} />,
  },
  {
    title: "Output",
    dataIndex: "outputLanguage",
    sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data} />,
  },
  {
    title: "Expires In",
    dataIndex: "expiry",
    sorter: true,
    width: 80,
    render: (data) => {
      if (data === "1 day") {
        return <div style={{ color: "red" }}>{data}</div>;
      } else if (data.includes("hours")) {
        return <div style={{ color: "red" }}>{data}</div>;
      } else {
        return <IsDataNull data={data} />;
      }
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    width: 70,
    render: (data) => {
      const maxLength = 25;
      let shortenedData = data;

      if (data.length > maxLength) {
        shortenedData = data.substring(0, maxLength) + "...";
      }

      if (data.length > maxLength) {
        return shortenedData;
      } else {
        return <IsDataNull data={data} />;
      }
    },
  },
  {
    title: "Actions",
    dataIndex: "action",
    width: "7%",
    align: "center",
    render: (data, record) => {
      return (
        <ProjectsTabAction
          data={data}
          record={record}
        />
      );
    },
  },
];

export default EngageTranslationTableColumns;
