import { Document, Page, pdfjs } from "react-pdf";
import { useState } from "react";
import { FontIcon } from "@fluentui/react";
import { Spin } from "antd";
import "./PDFViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PDFViewer(props: any) {
  const [numPages, setNumPages] = useState<number>();
  const [scale, setScale] = useState<number>(1);

  return (
    <Document
      className="document-container"
      file={props.document}
      loading={
        <div style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", top: "47%" }}>
          <Spin size="large" />
        </div>
      }
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
      <div className="document-toolbar">
        <button
          onClick={() => {
            if (scale > 0.5) {
              setScale(scale - 0.25);
            }
          }}
          className="document-toolbar-btn">
          <FontIcon iconName="CalculatorSubtract" />
        </button>
        <div className="document-toolbar-input">{`${scale * 100}%`}</div>
        <button
          onClick={() => {
            if (scale < 2.5) {
              setScale(scale + 0.25);
            }
          }}
          className="document-toolbar-btn">
          <FontIcon iconName="Add" />
        </button>
      </div>
      {Array.apply(null, Array(numPages))
        .map((x, i) => i + 1)
        .map((page) => (
          <>
            <Page
              className="document-page"
              pageNumber={page}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              scale={scale}>
              <div className="document-page-count">
                Page {page} of {numPages}
              </div>
            </Page>
          </>
        ))}
      <div></div>
    </Document>
  );
}
