import { UploadFile } from "antd";

export interface defaultRequestBodyType {
  pageNum: number;
  pageSize: number;
  sortColumn: string;
  isAsc: boolean;
  keyword: string;
  source: string;
}

export const defaultRequestBody: defaultRequestBodyType = {
  pageNum: 1,
  pageSize: 30,
  sortColumn: "id",
  isAsc: false,
  keyword: "",
  source: "clientSite",
};

export interface DocumentPropertyType {
  id: number;
  name: string;
  engageProductionId: number;
  format: string;
  pages: number;
  client: string;
  sourceLanguage: string;
  targetLanguage: string;
  status: string;
}

export interface DocumentAuditType {
  id: number;
  createdAt: string;
  createdBy: string;
  shortMessage: string;
  changes: string;
}

interface ClientType {
  id: number;
  name: string;
}
interface SourceLanguageType {
  id: number;
  name: string;
}
interface TargetLanguageType {
  id: number;
  name: string;
}
export interface DocumentDetailType {
  sourceLanguageId: number;
  targetLanguageIds: number;
  uploadedBy: string;
  tags: string;
  additionalNotes: string;
  isQcRequired: boolean;
}

export interface DocumentUploadArgs extends DocumentDetailType {
  file: UploadFile;
}
export interface DocumentUpdateArgs extends DocumentDetailType {}

export const defaultPresignedDocumentApiResponse = {
  id: 717,
  clientId: 3,
  clientName: "Marketing Innovations Ltd.",
  name: "Anji Reddy Nusum",
  description: "",
  localFilePath: "",
  engageProductionId: 242424,
  uploadedBy: "nusum.anjireddy@williamslea.com",
  qcAssignedTo: "nusum.anjireddy@williamslea.com",
  sourceLanguageId: 4,
  targetLanguageId: 6,
  targetLanguageName: "Dutch",
  sourceLanguageName: "English",
  tags: "",
  currentStatus: "On Hold In Progress",
  inputPath: "Marketing-Innovations-Ltd./Inbound/717/Translation/Dutch_MRF_1_Page.pdf",
  outputPath: "Marketing-Innovations-Ltd./Outbound/717/Translation/Dutch_MRF_1_Page.pdf",
  inputPathPresignedUrl:
    "https://wl-dev-idp-engage-translate-docstore.s3.amazonaws.com/Marketing-Innovations-Ltd./Inbound/717/Translation/Dutch_MRF_1_Page.pdf?X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIHG1hTWEsIGjxZIY%2FdF2%2F9K4S8gNIyxDoZpTtrc9V5EiAiA%2BQsZEYQ57hY%2F%2Bd1dD7HUneMzGBDQoOvyCZYFgnwth0iqYAwjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDg4OTA5NzE0NDgwNCIM0w0EV8TTKvRf3TGyKuwC68UQ6%2FJhvinxpwuLvOvg8dsOAvkTxmuhDDBvXflV3gX9S5hntCG8WrxDX63O8jOZ6Ugv3%2FZHtQM05PV%2BOmTxD28uCqKf6LhaENzXe8BJkzwVzBLPGzRZJkxFtd6kvwIuxP5mRtNP0tE7uV8u3DVvL3g40G8wSxw5P65gkjKBjjMNitzlTIrEAxMzrben63QRqxVOdHuwoOaDwdxZJAMPsMiDtjgN0is9uoWxXb134%2BL2N12zhgFLgVqHR69JFWNyArME0h0faeu4LyDH8BcjamLPD5fG94xwlYjJMMhiam3Wzn2rA23BIXd2%2B6qtGZ6%2BFQenwxYVzh%2B69XuP%2FiwgotYr0PRfrNujIY4zZDca2mCN5gDfuFF1GkoNGgkrEfrfWNxwht%2FMeJ2bEgqecE%2ByDmdssbFRSdHGWUKoyiXw0wPM%2FDBuBXZpLka%2FiQ0yxqzjNPT1OtQBAW4zgqE%2FlUgTXtId6wWC3Kc3qs5v1jCnq6KrBjqeAaWJlUgRg8zVLQGQVBBMc4Cu0k6AiGGzloLo%2B4GvhgLoxOpn9CmFng0z9Cfml1hvO2ePY%2Ff5b5kCEYTE6%2FgeeyoUkJpVzp%2FL%2BmmihS2a%2Fy8%2FCHFMo12GND6ZGc8psVFT%2BFu3Ah5qLsOth1MTYSOQtrruIWPOp54Zgen9B5Jp9o2rUeOFGZYnJaXaxMLQ%2Br6DUCZ4wlB%2FvmbeJBjeqd8v&response-cache-control=No-cache&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA46ASRZHSDLQCKWEF%2F20231130%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231130T140409Z&X-Amz-SignedHeaders=host&X-Amz-Signature=e49c97e8b1391892dc3bd7c23c897c84817d11c02368f14d61611bfbb06bc17f",
  outputPathPresignedUrl:
    "https://wl-dev-idp-engage-translate-docstore.s3.amazonaws.com/Marketing-Innovations-Ltd./Outbound/717/Translation/Dutch_MRF_1_Page.pdf?X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIHG1hTWEsIGjxZIY%2FdF2%2F9K4S8gNIyxDoZpTtrc9V5EiAiA%2BQsZEYQ57hY%2F%2Bd1dD7HUneMzGBDQoOvyCZYFgnwth0iqYAwjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDg4OTA5NzE0NDgwNCIM0w0EV8TTKvRf3TGyKuwC68UQ6%2FJhvinxpwuLvOvg8dsOAvkTxmuhDDBvXflV3gX9S5hntCG8WrxDX63O8jOZ6Ugv3%2FZHtQM05PV%2BOmTxD28uCqKf6LhaENzXe8BJkzwVzBLPGzRZJkxFtd6kvwIuxP5mRtNP0tE7uV8u3DVvL3g40G8wSxw5P65gkjKBjjMNitzlTIrEAxMzrben63QRqxVOdHuwoOaDwdxZJAMPsMiDtjgN0is9uoWxXb134%2BL2N12zhgFLgVqHR69JFWNyArME0h0faeu4LyDH8BcjamLPD5fG94xwlYjJMMhiam3Wzn2rA23BIXd2%2B6qtGZ6%2BFQenwxYVzh%2B69XuP%2FiwgotYr0PRfrNujIY4zZDca2mCN5gDfuFF1GkoNGgkrEfrfWNxwht%2FMeJ2bEgqecE%2ByDmdssbFRSdHGWUKoyiXw0wPM%2FDBuBXZpLka%2FiQ0yxqzjNPT1OtQBAW4zgqE%2FlUgTXtId6wWC3Kc3qs5v1jCnq6KrBjqeAaWJlUgRg8zVLQGQVBBMc4Cu0k6AiGGzloLo%2B4GvhgLoxOpn9CmFng0z9Cfml1hvO2ePY%2Ff5b5kCEYTE6%2FgeeyoUkJpVzp%2FL%2BmmihS2a%2Fy8%2FCHFMo12GND6ZGc8psVFT%2BFu3Ah5qLsOth1MTYSOQtrruIWPOp54Zgen9B5Jp9o2rUeOFGZYnJaXaxMLQ%2Br6DUCZ4wlB%2FvmbeJBjeqd8v&response-cache-control=No-cache&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA46ASRZHSDLQCKWEF%2F20231130%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231130T140409Z&X-Amz-SignedHeaders=host&X-Amz-Signature=56699fef9bbcb71837a1690f89bdafb1c66ac79bc25b36930d82d21c3ec6e7ca",
  isOutputDocEditable: true,
  fileFormat: "pdf",
  sfdtInput: {},
  sfdtOutput: {},
};

export const defaultSelectedDocument = {
  metaData: {
    id: 0,
    name: "",
    description: "",
    clientId: 0,
    engageProductionId: 0,
    sourceLanguageId: 0,
    targetLanguageId: 0,
    uploadedBy: "",
    qcAssignedTo: "",
    status: "",
  },

  loading: false,
  selectedDocumentID: 0,
  error: "",
};
//give me the type Obj by not using typeof
export type PresignedDocumentApiResponseType = typeof defaultPresignedDocumentApiResponse;
