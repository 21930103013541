import { Spin, Tabs, TabsProps, UploadFile } from "antd";
import { useEffect, useState } from "react";
import Search from "antd/es/input/Search";
import QuickTextTranslatePage from "../QuickTextTranslatePage/QuickTextTranslatePage";
import ProjectsPage from "../ProjectsPage/ProjectsPage";
import UploadPage from "../UploadPage/UploadPage";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { fetchDocuments, searchDocuments } from "../../redux/EngageTranslation/EngageTranslationAction";
import { useAppDispatch } from "../../redux/useAppDispatch";
import "./EngageTranslatePage.css";

function EngageTranslationPage(props: any) {
  const location = useLocation();
  const searchParams = location.search.split("=")[1];
  const tabsArray = ["text-translate", "upload", "documents"];
  const [activeTab, setActiveTab] = useState(tabsArray.includes(searchParams) ? searchParams : "text-translate");
  const [searchValue, setSearchValue] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [retryAttempts, setRetryAttempts] = useState(3);
  const quickTranslationPageState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.quickTranslationPage);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const options = {
      pathname: "/view",
      search: `=${activeTab}`,
    };
    navigate(options, { replace: true });
  }, [activeTab]);

  const tabItems: TabsProps["items"] = [
    {
      key: "text-translate",
      label: <b>Text</b>,
      children: <QuickTextTranslatePage />,
    },
    {
      key: "upload",
      label: <b>Upload</b>,
      children: (
        <UploadPage
          setFileList={setFileList}
          fileList={fileList}
          setRetryAttempts={setRetryAttempts}
          retryAttempts={retryAttempts}
        />
      ),
    },
    {
      key: "documents",
      label: <b>Documents</b>,
      children: <ProjectsPage />,
    },
  ];

  useEffect(() => {
    if (activeTab === "documents") {
      dispatch(fetchDocuments({ view: "document" }));
    }
  }, [activeTab]);

  const onSearch = (value: string) => {
    dispatch(
      searchDocuments({
        view: "document",
        searchKeyword: value,
      })
    );
  };

  useEffect(() => {
    if (quickTranslationPageState.loading) {
      setPageLoading(true);
    } else {
      setPageLoading(false);
    }
  }, [quickTranslationPageState.loading]);

  return (
    <div className="body-container pt-3 h-100 gap-3">
      {pageLoading && (
        <div
          id="spinner"
          style={{ zIndex: 10000, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, width: "100%", height: "100%", backgroundColor: "#8080807a", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spin size="large" />
        </div>
      )}
      <div className="h-100 position-relative overflow-hidden">
        <Tabs
          type="card"
          size="large"
          activeKey={activeTab}
          style={{ height: "100%" }}
          items={tabItems}
          renderTabBar={(props: TabsProps) => (
            <div className="tab-bar-container">
              <div className="tab-bar-inner d-flex gap-1 h-100">
                {tabItems.map((item, i) => (
                  <div
                    key={i}
                    className={`tab-btn ${item.key === activeTab ? "active-tab" : ""}`}
                    onClick={() => setActiveTab(item.key)}>
                    {item.label}
                  </div>
                ))}
              </div>

              {activeTab === "documents" && (
                <>
                  <div className="search-bar">
                    <Search
                      type="text"
                      size="large"
                      value={searchValue}
                      onSearch={(value) => onSearch(value.trimEnd().trimStart())}
                      allowClear
                      placeholder="Search..."
                      onChange={(e) => setSearchValue(e.target.value)}
                      //style to make the content in the center horizontally of the parent position relative
                    />
                  </div>
                </>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default EngageTranslationPage;
