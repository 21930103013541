import React from "react";
import "./ErrorPage.css";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

interface ErrorPageProps {
  errorMessage?: string;
  errorCode?: number;
  errorDescription?: string;
  actionBtn: React.ReactNode;
}

function ErrorPage(props: ErrorPageProps) {
  return (
    <div
      className="app"
      style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", gap: 30 }}>
      <h4>{props.errorMessage}</h4>
      <p>{props.errorDescription}</p>
      {props.actionBtn}
    </div>
  );
}

export default ErrorPage;
