import { FontIcon } from "@fluentui/react";
import { Tooltip } from "antd";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { fetchDocuments, getDocumentInfo, getDocumentPresigned, openDeleteModal, setSelectedName, setSelectedRecord, stopTranslation } from "../../redux/EngageTranslation/EngageTranslationAction";

interface ProjectTabActionType {
  data: any;
  record: any;
}

function ProjectsTabAction(props: ProjectTabActionType) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="d-flex justify-content-around gap-2">
      {props.record.status === "Translation In Queue" && (
        <>
          <Tooltip
            placement="bottom"
            title={"Stop translation"}>
            <button
              onClick={() => {
                dispatch(stopTranslation({ documentID: props.record.documentId, ID: props.record.id }));
              }}
              className="button action">
              <FontIcon iconName="Blocked2" />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              disabled
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}
      {props.record.status === "Completed" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                  // dispatch(getDocumentInfo({ documentID: props.record.documentId, ID: props.record.id }));

                  navigate(`/view-document/${props.record.documentId}?target=${props.record.id}&language=${props.record.outputLanguage.replace(/\s/g, "")}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              disabled
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}
      {props.record.status === "In Progress" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // setSelectedRecord({ selectedRecordID: props.record.id });
                  // dispatch(getDocumentInfo({ selectedRecordID: props.record.id }));
                  // navigate(`/view-document/${props.record.documentId}/${props.record.id}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              disabled
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}
      {props.record.status === "Failed" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // setSelectedRecord({ selectedRecordID: props.record.id });
                  // dispatch(getDocumentInfo({ selectedRecordID: props.record.id }));
                  // navigate(`/view-document/${props.record.documentId}/${props.record.id}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "New" && (
        <>
          <Tooltip
            placement="bottom"
            title={"Download document"}>
            <button
              disabled
              className="button action">
              <FontIcon iconName="Download" />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // setSelectedRecord({ selectedRecordID: props.record.id });
                  // dispatch(getDocumentInfo({ selectedRecordID: props.record.id }));
                  navigate(`/view-document/${props.record.documentId}/${props.record.id}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "Virus Scan In Progress" && (
        <>
          <Tooltip
            placement="bottom"
            title={"Download document"}>
            <button
              disabled
              className="button action">
              <FontIcon iconName="Download" />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {}}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              disabled
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "Translation Failed" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // setSelectedRecord({ selectedRecordID: props.record.id });
                  // dispatch(getDocumentInfo({ selectedRecordID: props.record.id }));
                  // navigate(`/view-document/${props.record.documentId}/${props.record.id}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "Translation Stopped" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {}}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "Expired" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {}}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "Translation In Progress" && (
        <>
          <Tooltip
            placement="bottom"
            title={"Stop translation"}>
            <button
              className="button action"
              onClick={() => {
                dispatch(stopTranslation({ documentID: props.record.documentId, ID: props.record.id }));
              }}>
              <FontIcon iconName="Blocked2" />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {}}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              disabled
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "On Hold" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {}}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              disabled
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "QC In Progress" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // setSelectedRecord({ selectedRecordID: props.record.id });
                  // dispatch(getDocumentInfo({ selectedRecordID: props.record.id }));
                  navigate(`/view-document/${props.record.documentId}/${props.record.id}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              disabled
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "QC Ready" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // setSelectedRecord({ selectedRecordID: props.record.id });
                  // dispatch(getDocumentInfo({ selectedRecordID: props.record.id }));
                  navigate(`/view-document/${props.record.documentId}/${props.record.id}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              disabled
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "QC Failed" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // setSelectedRecord({ selectedRecordID: props.record.id });
                  // dispatch(getDocumentInfo({ selectedRecordID: props.record.id }));
                  // navigate(`/view-document/${props.record.documentId}/${props.record.id}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}

      {props.record.status === "QC to Start" && (
        <>
          <Tooltip
            placement="bottom"
            title={"View document"}>
            <button
              disabled
              className="button action">
              <FontIcon
                iconName="View"
                onClick={() => {
                  // setSelectedRecord({ selectedRecordID: props.record.id });
                  // dispatch(getDocumentInfo({ selectedRecordID: props.record.id }));
                  navigate(`/view-document/${props.record.documentId}/${props.record.id}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Audit history"}>
            <button className="button action">
              <FontIcon
                iconName="ComplianceAudit"
                onClick={() => {
                  navigate(`/audit-log/${props.record.documentId}`);
                }}
              />
            </button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={"Delete translation job"}>
            <button
              disabled
              className="button action"
              onClick={() => {
                dispatch(setSelectedRecord({ selectedDocumentID: props.record.documentId, selectedID: props.record.id }));
                dispatch(setSelectedName({ selectedRecordName: props.record.name }));
                dispatch(openDeleteModal());
              }}>
              <FontIcon iconName="Delete" />
            </button>
          </Tooltip>
        </>
      )}
    </div>
  );
}

export default ProjectsTabAction;
