import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import EngageTranslationSlice from "./EngageTranslation/EngageTranslationSlice";
// import { logger } from "redux-logger";

export const rootReducer = combineReducers({ engageTranslationSlice: EngageTranslationSlice });
export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({ reducer: rootReducer, middleware: [thunk] });
export type AppDispatch = typeof store.dispatch;
export default store;
