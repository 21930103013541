import { FontIcon, Modal } from "@fluentui/react";
import TableComponent from "../../components/TableComponent/TableComponent";
import Table, { ColumnsType } from "antd/es/table";
import IsDataNull from "../../components/Template/IsDataNull";
import { useLocation } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import {
  auditLogModalClose,
  fetchDocuments,
  resetTabState,
  // getDocumentAuditLog,
  updateRequestBody,
} from "../../redux/EngageTranslation/EngageTranslationAction";
import { useAppDispatch } from "../../redux/useAppDispatch";
import BackBtn from "../../components/Template/BackBtn";
import AuditLogColumns from "../../components/Columns/AuditLogColumns";
import AuditLogModal from "../../components/Modals/AuditLogModal/AuditLogModal";
import { Spin } from "antd";

function AuditLogPage() {
  const location = useLocation();
  const urlDivided = location.pathname.split("/");
  const documentIdFromUrl = Number(urlDivided[urlDivided.length - 1]);
  const dispatch = useAppDispatch();
  const modalStatus = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.auditLogPageState.modalStatus);
  const auditData = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.auditLogPageState);

  useEffect(() => {
    dispatch(fetchDocuments({ view: "audit-log", documentId: documentIdFromUrl }));

    return () => {
      dispatch(resetTabState("audit-log"));
    };
  }, []);

  const handleTableSorting = (sorter: any) => {
    const isAscend = (order: string) => {
      if (order === "ascend") {
        return true;
      }
      return false;
    };
    const newSortingRequestBody = {
      ...auditData.currentRequestBody,
      sortColumn: sorter.field,
      isAsc: isAscend(sorter.order),
      viewName: "audit-log",
    };
    dispatch(resetTabState("audit-log"));
    dispatch(
      updateRequestBody({
        requestBody: newSortingRequestBody,
        view: "audit-log",
      })
    );
    dispatch(fetchDocuments({ view: "audit-log", documentId: documentIdFromUrl }));
  };

  return (
    <div className="d-flex flex-column full-flex h-100 px-2 overflow-hidden">
      {auditData.loading ? (
        <div
          id="spinner"
          style={{ zIndex: 2, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="d-flex align align-items-center mt-3 mb-3 gap-3">
            <BackBtn />
            <h4>{auditData.resultHeading}</h4>
          </div>
          <div className="d-flex flex-column full-flex gap-3 overflow-hidden">
            <TableComponent
              dataSource={auditData.data}
              columns={AuditLogColumns}
              loading={auditData.loading}
              pagination={auditData.pagination}
              handleTableSorting={handleTableSorting}
              documentId={documentIdFromUrl}
              tableKey={"audit-log"}
            />
          </div>
          <AuditLogModal
            openState={modalStatus}
            close={() => dispatch(auditLogModalClose())}
          />
        </>
      )}
    </div>
  );
}

export default AuditLogPage;
