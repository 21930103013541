import * as yup from "yup";

//generate me file format array

export const loginValidationScheme = yup.object().shape({
  email: yup.string().email("Provide a valid email.").max(64, "Must be 64 characters or less.").required("Email is required."),
  password: yup.string().required("Password is required"),
});
// export const registerValidationScheme = yup.object().shape({
//   name: yup.string().required("Name is required"),
//   email: yup.string().email("Provide a valid email.").max(64, "Must be 64 characters or less.").required("Email is required."),
//   password: yup.string().required("Password is required").min(8, "Password must be 8 characters long").matches(/[0-9]/, "Password requires a number").matches(/[a-z]/, "Password requires a lowercase letter").matches(/[A-Z]/, "Password requires an uppercase letter").matches(/[^\w]/, "Password requires a symbol"),
//   confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match."),
// });
export const fileUploadValidationScheme = yup.object().shape({
  // name: yup.string().required("Name is required"),
  file: yup.mixed().required("File is required"),
  //   .mixed()
  //   .nullable()
  //   .notRequired()
  //   .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
  //     return !value || (value && value.size <= MAX_FILE_SIZE);
  //   })
  //   .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
});

export const folderCreationValidationScheme = yup.object().shape({
  //Name cannot contain '/', produce me yup validation error if name contains '/':
  name: yup
    .string()
    .required("Name is required")
    .matches(/^[^/]*$/, 'Folder Name cannot contain a forward slash ("/")'),
});

export const folderDeleteValidationScheme = yup.object().shape({
  confirmText: yup
    .string()
    .required("Please type 'permanently delete' to confirm deletion")
    .matches(/permanently delete/, "Please type 'permanently delete' to confirm deletion"),
});

export const brandArchiveValidationScheme = yup.object().shape({
  confirmText: yup
    .string()
    .required("Please type 'permanently archive' to confirm deletion")
    .matches(/permanently delete/, "Please type 'permanently delete' to confirm deletion"),
});
