import { useEffect, useRef } from "react";
import { DocumentEditorContainerComponent, Toolbar } from "@syncfusion/ej2-react-documenteditor";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import "./DocumentEditorViewer.css";
import { Spin } from "antd";
DocumentEditorContainerComponent.Inject(Toolbar);

function DocumentEditorViewer(props: any) {
  const loadingState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.viewDocumentState.loading);
  const viewDocumentState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.viewDocumentState);
  const container = useRef(null) as any;

  const renderComplete = async () => {
    container.current.documentEditor.resize();
    container.current.documentEditor.acceptTab = true;
    // container.current.documentEditor.open(props.document);
    container.current.documentEditor.open(JSON.stringify(props.document));
  };

  useEffect(() => {
    if (container.current) {
      renderComplete();
    }
  }, [viewDocumentState]);

  if (loadingState && !props.document) {
    return (
      <div style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", top: "47%" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="document-container-word">
      <DocumentEditorContainerComponent
        ref={container}
        style={{ display: "block", position: "relative" }}
        height="100%"
        serviceUrl="https://services.syncfusion.com/react/production/api/documenteditor/"
        locale="en-US"
        enableLocalPaste={true}
        restrictEditing={true}
        readOnly={true}
        toolbarItems={["Find"]}
      />
    </div>
  );
}

export default DocumentEditorViewer;
