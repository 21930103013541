import { auditLogModalData, auditLogModalOpen } from "../../redux/EngageTranslation/EngageTranslationAction";
import { useAppDispatch } from "../../redux/useAppDispatch";

const AuditLink = ({ record }: any) => {
  const dispatch = useAppDispatch();

  return (
    <a
      onClick={() => {
        dispatch(auditLogModalData({ data: record }));
        dispatch(auditLogModalOpen());
      }}
      className="text-primary text-decoration-underline">
      More
    </a>
  );
};

export default AuditLink;
