import { FontIcon, Icon } from "@fluentui/react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { notification } from "antd";
import React, { ReactNode, memo, useEffect } from "react";
import { getExtension } from "../../utils";

export const openNotification = (message: string, description: any, data?: any) => {
  console.log("NOTIF DATA", data);
  switch (message) {
    // Edit Document
    case "Edit Document":
      notification.open({
        message: (
          <div className="px-3">
            <b>{message}</b>
          </div>
        ),
        description: (
          <div className="notification px-3">
            Document <b>{data.name}</b> (ID: {data.id}) updated successfully
          </div>
        ),
      });
      break;
    // Stop Translation
    case "Stop Translation":
      notification.open({
        message: (
          <div className="px-3">
            <b>{message}</b>
          </div>
        ),
        description: (
          <div className="notification px-3">
            Translation of the document{" "}
            <b>
              ID: {data} /{data.ID}
            </b>
            is stopping
          </div>
        ),
      });
      break;
    // Delete Document
    case "Deleted Document":
      notification.open({
        message: (
          <div className="px-3">
            <b>{message}</b>
          </div>
        ),
        description: (
          <div className="notification px-3">
            Document <b>{data.name}</b> (ID: {data.data.id} / {data.ID}) deleted successfully
          </div>
        ),
      });
      break;
    // Upload Document
    // case "Document Uploaded":
    //   notification.open({
    //     message: (
    //       <div className="px-3">
    //         <b>{message}</b>
    //       </div>
    //     ),
    //     description: (
    //       <div className="notification px-3">
    //         Document <b>{data.name}</b> (ID: {data.id}) uploaded successfully
    //       </div>
    //     ),
    //   });
    //   break;
    default:
      notification.open({
        message: (
          <div className="px-3">
            <b>{message}</b>
          </div>
        ),
        description: <div className="notification px-3">{description}</div>,
      });
  }
};

export const openNotificationWithIcon = (type: boolean, data: any, extension: string) => {
  notification.open({
    message: <b className="px-3">{type === true ? "File Upload Successful" : "File Upload Failed"}</b>,
    description: (
      <div className="notification px-3">
        {type === true ? (
          <div>
            The document <b>{data.name}</b> (ID: {data.id}) has been uploaded successfully
          </div>
        ) : (
          <span>
            The document <b>{data.name}</b> has been uploaded unsuccessfully
          </span>
        )}
      </div>
    ),
    icon:
      type === true ? (
        <Icon
          {...getFileTypeIconProps({
            extension: extension.split(".")[1],
            size: 40,
            imageFileType: "svg",
          })}
        />
      ) : (
        <FontIcon
          iconName="AlertSolid"
          className="mt-4"
        />
      ),
  });
};
