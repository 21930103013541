import React from "react";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { clearUploadStatusData } from "../../redux/EngageTranslation/EngageTranslationAction";

function BackBtn(props: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <button
      className="button circle d-flex justify-content-center align-items-center"
      onClick={() => {
        dispatch(clearUploadStatusData());
        navigate(-1);
      }}>
      <span className="material-symbols-outlined">arrow_back</span>
    </button>
  );
}

export default BackBtn;
