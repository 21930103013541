const region = "us-east-1";
const origin = window.location.origin;

const prod = {
  appSettingsData: {
    // generate a file format limit of video file
    federatedEngageAIProvider: "wl-qa-engage-translate-idp",
  },

  amplify_config: {
    Auth: {
      mandatorySignIn: true,
      region: region,
      userPoolId: "us-east-1_JgTXuOFsg",
      userPoolWebClientId: "186smucast5tsp13b3ifvr9vpt",
    },
    oauth: {
      domain: "wl-qa-engage-translate.auth.us-east-1.amazoncognito.com",
      scope: [],
      // redirectSignIn: "http://localhost:3000/login",
      // redirectSignOut: "http://localhost:3000/login",
      redirectSignIn: `${origin}/documents`,
      redirectSignOut: `${origin}/login`,
      responseType: "code",
      clientId: "186smucast5tsp13b3ifvr9vpt",
    },
    API: {
      endpoints: [
        {
          name: "engage-translate",
          // endpoint: "https://api.dev.wl-dms.com",
          endpoint: "https://api.qa.translate.wl-engage.com",
          region: region,
        },
      ],
    },
  },
};

export type ProdType = typeof prod;
export default prod;
