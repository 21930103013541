const region = "us-east-1";
const origin = window.location.origin;

const dev = {
  appSettingsData: {
    // generate a file format limit of video file
    federatedEngageAIProvider: "wl-dev-translate-client-site-idp",
  },

  amplify_config: {
    Auth: {
      mandatorySignIn: true,
      region: region,
      userPoolId: "us-east-1_J2sIUtuBw",
      userPoolWebClientId: "qd21e5nptfaurk11vrg02qfg3",
    },
    oauth: {
      domain: "wl-dev-idp-translate-client-site.auth.us-east-1.amazoncognito.com",
      scope: [],
      redirectSignIn: `${origin}/view`,
      redirectSignOut: `${origin}/`,
      responseType: "code",
      clientId: "qd21e5nptfaurk11vrg02qfg3",
    },
    API: {
      endpoints: [
        {
          name: "engage-translate",
          // endpoint: "https://api.dev.wl-dms.com",
          endpoint: "https://api.dev.translate.wl-engage.com",
          region: region,
        },
      ],
    },
  },
};

export type DevType = typeof dev;
export default dev;
