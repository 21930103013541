import { ColumnsType } from "antd/es/table";
import IsDataNull from "../Template/IsDataNull";
import AuditLink from "../Template/AuditLink";

export interface AuditLogColumnsProperties {
  id: number;
  createdBy: string;
  createdAt: string;
  shortMessage: string;
}

const AuditLogColumns: ColumnsType<AuditLogColumnsProperties> = [
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    defaultSortOrder: "ascend",
    sorter: true,
    width: 200,
    render: (data) => {
      const dateTime = data.split("T");
      const date = dateTime[0];
      const time = dateTime[1].split(".")[0];
      const formattedDateTime = date + " " + time;
      return <IsDataNull data={formattedDateTime} />;
    },
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy",
    sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data} />,
  },
  {
    title: "Description",
    dataIndex: "shortMessage",
    key: "shortMessage",
    sorter: true,
    width: 150,
    render: (data) => <IsDataNull data={data} />,
  },
  {
    title: "Changes",
    dataIndex: "changes",
    key: "changes",
    // sorter: true,
    width: 250,
    render: (data, record) => {
      const fullData = data.split("|||");
      const splitData = data.split("|||").splice(0, 4);

      return (
        <div>
          {/* <div>{text}</div> */}
          {splitData.map((text: string, index: number) => {
            return <div key={index}>{text}</div>;
          })}
          {fullData.length > splitData.length && <AuditLink record={record} />}
        </div>
      );
    },
  },
];

export default AuditLogColumns;
