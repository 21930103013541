/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import "./Nav.css";

function AuthNav(props: any) {
  const [user, setUser] = useState("");
  const userData = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem("userData"))));

  useEffect(() => {
    setUser(userData.idToken.payload.name);
  }, [userData]);

  return (
    <nav className="navbar navbar-expand-lg d-flex flex-column pb-0 position-sticky top-0 authnav">
      <div className="container-fluid d-flex align-items-center px-4 py-2">
        <div className="navbar-nav gap-3 align-items-center ">
          {/* <ToggleBtn /> */}
          <a
            className="navbar-brand "
            href="/">
            <img
              src={require("../../assets/img/engage translate_logo_slate_small.png")}
              alt=""
            />
          </a>
        </div>

        <div
          className="flex-grow-0 "
          id="navbarNavAltMarkup">
          <div className="dropdown">
            <button
              className="user-container"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <div className="d-flex align-items-center gap-1">
                <span className="material-symbols-outlined">person</span>
                <span>{user}</span>
                <span className="material-symbols-outlined">arrow_drop_down</span>
              </div>
            </button>

            <div
              className="dropdown-menu drop-menu-container "
              aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item dropdown-item-customize"
                onClick={() => console.log("logout")}
                href="/">
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default AuthNav;
