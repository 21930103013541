import TextArea from "antd/es/input/TextArea";
import "./QuickTextTranslatePage.css";
import { useEffect, useRef, useState } from "react";
import { FontIcon, Spinner } from "@fluentui/react";
import { Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { fetchQuickTranslateLanguageList, fetchTargetLanguageList, quickTextTranslation } from "../../redux/EngageTranslation/EngageTranslationAction";

const QuickTextTranslatePage = () => {
  const [text, setText] = useState<string>("");
  const [translatedText, setTranslatedText] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [sourceLangSelection, setSourceLangSelection] = useState();
  const [targetLangSelection, setTargetLangSelection] = useState();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isTranslateBtnEnabled, setIsTranslateBtnEnabled] = useState<boolean>(false);
  const LanguageFormState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.quickTextTranslateDataState);
  const quickTranslationPageState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.quickTranslationPage);
  const dispatch = useAppDispatch();

  const handleCopied = () => {
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  useEffect(() => {
    if (!sourceLangSelection || !targetLangSelection || !text) {
      setIsTranslateBtnEnabled(false);
    } else {
      setIsTranslateBtnEnabled(true);
    }
  }, [sourceLangSelection, targetLangSelection, text]);

  useEffect(() => {
    setTranslatedText(quickTranslationPageState.data.translatedText);
    setError(quickTranslationPageState.error ?? "");
  }, [quickTranslationPageState.data.translatedText, quickTranslationPageState.error]);

  useEffect(() => {
    dispatch(fetchQuickTranslateLanguageList());
  }, []);

  const handleTranslate = () => {
    if (sourceLangSelection && targetLangSelection && text) {
      dispatch(quickTextTranslation({ inputText: text, sourceLanguageId: sourceLangSelection, targetLanguageId: targetLangSelection }));
    }
    setIsTranslateBtnEnabled(false);
  };

  const arrangeLanguagesAlphabetically = (languagesArray: Array<{ label: string; value: number; id: number }>) => {
    return [...languagesArray].sort((a: any, b: any) => a.label.localeCompare(b.label, "en", { sensitivity: "base" }));
  };

  // if (LanguageFormState.loading) {
  //   return (
  //     <div
  //       className="app"
  //       style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
  //       <Spin size="large" />
  //     </div>
  //   );
  // }

  return (
    <Spin spinning={LanguageFormState.loading}>
      <div className="QT-page-container">
        <div className="QT-source-container">
          <div className="QT-nav-left">
            <h5>Source</h5>
            <Select
              style={{ marginTop: "5px", marginBottom: "5px", width: "200px" }}
              placeholder="Please select"
              onChange={(e) => {
                dispatch(fetchTargetLanguageList(e));
                setSourceLangSelection(e);
              }}
              options={arrangeLanguagesAlphabetically(LanguageFormState.sourceLanguageList)}
            />
          </div>
          <TextArea
            style={{ marginTop: "7px", marginBottom: "40px" }}
            value={text}
            placeholder="Enter text to translate..."
            showCount
            maxLength={5000}
            onChange={(e) => {
              setText(e.target.value);

              if (quickTranslationPageState.data) {
                setIsTranslateBtnEnabled(true);
              }
            }}
            autoSize={{
              minRows: 20,
            }}
            allowClear={true}
          />
        </div>
        <div className="QT-target-container">
          <div className="QT-target-nav">
            <div className="QT-nav-left">
              <h5>Target</h5>
              <Select
                style={{ marginTop: "5px", marginBottom: "5px", width: "200px" }}
                placeholder="Please select"
                onChange={(e) => setTargetLangSelection(e)}
                options={arrangeLanguagesAlphabetically(LanguageFormState.targetLanguageList)}
              />
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                onClick={() => {
                  handleTranslate();
                }}
                disabled={!isTranslateBtnEnabled}
                className="button QT-target-translate-btn">
                Translate
              </button>
              <button
                onClick={() => {
                  setTranslatedText("");
                  setText("");
                }}
                className="button">
                Clear
              </button>
            </div>
          </div>
          <TextArea
            style={{ marginTop: "7px", paddingRight: "24px", backgroundColor: "#f5f5f5" }}
            readOnly
            placeholder="Translation"
            value={translatedText}
            autoSize={{
              minRows: 20,
            }}
          />
          <div className="QT-target-footer">
            <div>{error && <div className="text-danger">{error}</div>}</div>
            <div className="d-flex align-items-center gap-1">
              {isCopied && <div className="QT-copied-text">Copied to clipboard!</div>}
              <FontIcon
                className="QT-copy-icon"
                iconName="Copy"
                onClick={() => {
                  navigator.clipboard.writeText(quickTranslationPageState.data.translatedText);
                  setIsCopied(true);
                  handleCopied();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default QuickTextTranslatePage;
