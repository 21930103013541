import React from "react";
import ToggleBtn from "../SidebarComponent/ToggleBtn/ToggleBtn";
import "./Nav.css";
import { useNavigate } from "react-router-dom";

function UnAuthNav(props: any) {
  const location = window.location.pathname;
  const navigate = useNavigate();
  return (
    <nav className="navbar navbar-expand-lg  position-sticky top-0">
      <div className="container-fluid d-flex align-items-center px-4 py-2">
        {/* <ToggleBtn /> */}

        <a
          className="navbar-brand "
          href="/">
          <img
            src={require("../../assets/img/engage ai_logo_slate.png")}
            alt=""
          />
        </a>
        {location !== "/" && (
          <div
            className="collapse navbar-nav d-flex justify-content-end "
            id="navbarNavAltMarkup">
            <div className=" gap-5">
              <button
                className="button"
                onClick={() => navigate("/")}>
                Login
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default UnAuthNav;
