import dev from "./environment.dev";
import prod from "./environment.prod";
import { StagingType } from "./environment.staging";
import { QaType } from "./environment.qa";
import { DevType } from "./environment.dev";
import { ProdType } from "./environment.prod";
import qa from "./environment.qa";
import staging from "./environment.staging";

const env = process.env.REACT_APP_NODE_ENV;
type environment = StagingType | QaType | DevType | ProdType;
let APP_CONFIG: environment;

switch (env) {
  case "dev":
    APP_CONFIG = dev;
    break;
  case "qa":
    APP_CONFIG = qa;
    break;
  case "staging":
    APP_CONFIG = staging;
    break;
  case "prod":
    APP_CONFIG = prod;
    break;
  default:
    APP_CONFIG = prod;
    break;
}
export default APP_CONFIG;
