import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import "./AuditLogModal.scss";

const AuditModalContent = (props: any) => {
  const recordData = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.auditLogPageState.modalData);

  const formattedDateTime = (data: string) => {
    if (data !== undefined) {
      const dateTime = data.split("T");
      const date = dateTime[0];
      const time = dateTime[1].split(".")[0];
      return date + " " + time;
    } else {
      return "-";
    }
  };

  return (
    <div className="ALM-container">
      <h3 className="ALM-title">Audit Details</h3>
      <div>
        <div className="ALM-row">
          <p className="ALM-subheading">Created By</p>
          <input
            className="ALM-field"
            type="text"
            value={recordData.createdBy || "-"}
            readOnly
            disabled
          />
        </div>
        <div className="ALM-row">
          <p className="ALM-subheading">Created Date</p>
          <input
            className="ALM-field"
            type="text"
            value={formattedDateTime(recordData.createdAt) || "-"}
            readOnly
            disabled
          />
        </div>
        <div className="ALM-row">
          <p className="ALM-subheading">Description</p>
          <input
            className="ALM-field"
            type="text"
            value={recordData.shortMessage || "-"}
            readOnly
            disabled
          />
        </div>
        <div className="ALM-row">
          <p className="ALM-subheading">Changes</p>
          <div className="ALM-textarea">
            {recordData.changes.split("|||").map((item: string, index: number) => (
              <div key={index}>{item || "-"}</div>
            ))}
          </div>
        </div>
        <div className="ALM-footer">
          <button
            className="ALM-close-btn"
            onClick={props.close}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuditModalContent;
