import React, { useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/SidebarComponent/Sidebar/Sidebar";
import Footer from "../components/FooterPanel/Footer";
import AuthNav from "../components/NavPanel/AuthNav";
import { isUserLoggedIn } from "../services/auth/AuthAPI";
import { Spin } from "antd";

function AuthLayout(props: any) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    const checkUserLogin = async () => {
      const status = await isUserLoggedIn();

      setIsAuthenticated(status);
      setLoading(false);
    };
    checkUserLogin();
  }, []);

  if (loading) {
    return (
      <div
        className="app"
        style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      {isAuthenticated ? (
        <div className="app">
          <AuthNav />
          <Sidebar />
          <Outlet />
          <Footer />
        </div>
      ) : (
        // <Redirect/>
        <Navigate to={"/"} />
      )}
    </>
  );
}

export default AuthLayout;
