import { Outlet } from "react-router-dom";
import Footer from "../components/FooterPanel/Footer";
import UnAuthNav from "../components/NavPanel/UnAuthNav";

function UnAuthLayout(props: any) {
  return (
    <div className="app">
      <UnAuthNav />
      <Outlet />
      <Footer />
    </div>
  );
}

export default UnAuthLayout;
