import "./App.css";
import UnAuthLayout from "./layout/UnAuthLayout";
import { Route, useNavigate } from "react-router";
import { Routes } from "react-router";
import colors from "./style.module.scss";
import AuthLayout from "./layout/AuthLayout";
import LoginPage from "./pages/LoginPage/LoginPage";
import EngageTranslationPage from "./pages/EngageTranslationPage/EngageTranslationPage";
import { initializeIcons } from "@fluentui/react";
import TranslationDetailPage from "./pages/TranslationDetailPage/TranslationDetailPage";
import { ConfigProvider } from "antd";
import { Amplify } from "aws-amplify";
import APP_CONFIG from "./configs/environment";
import { Provider } from "react-redux";
import store from "./redux/Store";
import { registerLicense } from "@syncfusion/ej2-base";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import ErrorPage from "./components/Template/ErrorPage/ErrorPage";
import AuditLogPage from "./pages/AuditLogPage/AuditLogPage";

function App() {
  const navigate = useNavigate();
  Amplify.configure(APP_CONFIG.amplify_config);
  initializeIcons(); // Initialize Fluent UI icons
  initializeFileTypeIcons(undefined);
  // Registering Syncfusion license key
  registerLicense("Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlfcHRdRWNeUURzV0A=");

  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: colors.colorPrimary },
      }}>
      <Provider store={store}>
        <Routes>
          <Route
            path=""
            element={<UnAuthLayout />}>
            <Route path="">
              <Route
                path=""
                element={<LoginPage />}
              />
            </Route>
          </Route>
          <Route
            path=""
            element={<AuthLayout />}>
            <Route
              path="/view"
              element={<EngageTranslationPage />}
            />
            <Route
              path="/view-document/:id"
              element={<TranslationDetailPage />}
            />
            <Route
              path="/audit-log/:id"
              element={<AuditLogPage />}
            />
          </Route>

          <Route
            path="*"
            element={
              <ErrorPage
                errorMessage="404 - Page Not Found."
                errorDescription="The page you're looking for might have been moved, deleted, or it could be temporarily unavailable."
                actionBtn={
                  <button
                    className="button"
                    onClick={() => navigate("/view?=text-translate")}>
                    Return
                  </button>
                }
              />
            }
          />
        </Routes>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
