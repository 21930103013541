import TableComponent from "../../components/TableComponent/TableComponent";
import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal";
import EngageTranslationTableColumns from "../../components/Columns/EngageTranslationTableColumns";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { closeDeleteModal, fetchDocuments, openDeleteModal, updateRequestBody } from "../../redux/EngageTranslation/EngageTranslationAction";

function ProjectsPage() {
  const dispatch = useAppDispatch();
  const documentTabState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.documentTabState);
  const deleteModalState = useSelector((state: RootState) => state.engageTranslationSlice.engageTranslateMainState.modals.deleteModalOpenState);

  const handleTableSorting = (sorter: any) => {
    const isAscend = (order: string) => {
      if (order === "ascend") {
        return true;
      }
      return false;
    };
    const newSortingRequestBody = { ...documentTabState.currentRequestBody, sortColumn: sorter.field, isAsc: isAscend(sorter.order) };
    dispatch(updateRequestBody({ requestBody: newSortingRequestBody, view: "document" }));
    dispatch(fetchDocuments({ view: "document" }));
  };

  return (
    <div
      style={{ width: "100%" }}
      className="d-flex flex-column full-flex h-100 overflow-hidden">
      <ConfirmDeleteModal
        open={() => dispatch(openDeleteModal())}
        close={() => dispatch(closeDeleteModal())}
        openState={deleteModalState}
      />

      <div className="d-flex flex-column full-flex gap-3 overflow-hidden">
        <TableComponent
          tableKey={"document"}
          dataSource={documentTabState.data}
          columns={EngageTranslationTableColumns}
          handleTableSorting={handleTableSorting}
          pagination={documentTabState.pagination}
          loading={documentTabState.loading}
        />
      </div>
    </div>
  );
}

export default ProjectsPage;
